import React from 'react';
import './desktop-main.css';
import "./desktop-zakazane-styly.css";
import "animate.css";

function Desktop() {
  return (
    <div>
  
    <img src='./img/PPL_obrázek.jpg' alt='developer hal' className='developer-hal-ppl'></img>

    {/* <img src='./img/logo.png' alt='logo' className='developer-hal-logo'></img> */}

    <a href='mailto:simpleeconomy@seznam.cz' className='simple-economy-mail-desktop'>simpleeconomy@seznam.cz</a>

    <div className='carka-navbar-desktop'></div>


    <nav class="navbar-desktop">
        <a href="#sluzby" class="nav-link-desktop">SLUŽBY</a>
        <a href="#reference" class="nav-link-desktop">REFERENCE</a>
        <a href="#kontakt" class="nav-link-desktop">KONTAKT</a>
    </nav>

    <h3 className='desktop-h3-first'>Společně budujeme vaši budoucnost a prosperitu.</h3>
    <h1 className='desktop-h1-first'>SIMPLE ECONOMY</h1>


    {/* <button className='desktop-button-1'>KONTAKT</button> */}

    <div className='jecnicka'>Projektové řízení</div>
    <div className='dvojka'>Služby developera</div>
    <div className='trojka'>Pozemkové úpravy</div>
    <div className='ctverka'>Due diligence</div>

    <div className='pokracovani-desktop'></div>



    <h1 id='sluzby' className='desktop-sluzby-main-text'>NAŠE SLUŽBY</h1>
    <h3 className='desktop-sluzby-second-text'>Realizace vašich vizí od A do Z</h3>

    <div className='desktop-pozadi-2'></div>

    <img src='./img/herales.png' className='obrazek1'></img>

    <img src='./img/vetro.jpg' className='obrazek2'></img>


<div id='reference'>
    {/* <h1 className='desktop-reference-main-text'>REFERENCE</h1> */}
    {/* <h3 className='desktop-reference-second-text'>Inspirujte se našimi projekty pro pro Vaši budoucnost</h3> */}


    <h3 className='desktop-reference-second-text2'>Spokojenost našich klientů je pro nás prioritou, a proto s hrdostí sdílíme jejich úspěšné projekty a pozitivní zpětnou vazbu.<br></br>
      Naše portfolio zahrnuje rozmanité projekty, od moderních rezidenčních budov až po inovativní komerční prostory.<br></br> Každý projekt je důkazem našeho závazku k vysoké kvalitě, 
      preciznímu provedení a efektivní spolupráci.<br></br><br></br> Naše práce mluví sama za sebe a jsme pyšní na to, že můžeme přispívat k vytváření výjimečných prostor pro bydlení i práci.</h3>

      <h3 className='desktop-reference-second-text3'>VÍCE INFORMACÍ</h3>
      </div>

    <div className='desktop-kontakt-background'></div>

      <h1 id='kontakt' className='desktop-kontakt-main-text'>KONTAKTNÍ INFORMACE</h1>
      <h3 className='desktop-kontakt-second-text'>S radostí Vám zodpovíme veškeré informace !</h3>




<h3 className='desktop-kontakt-1'><b><u>Společnost :</u></b> simple economy s.r.o.</h3>

<h3 className='desktop-kontakt-2'><b><u>Telefon :</u></b> simpleeconomy@seznam.cz</h3>

<h3 className='desktop-kontakt-2'><b><u>E-mail :</u></b> +420 724 582 843</h3>

<h3 className='desktop-kontakt-2'><b><u>Adresa :</u></b> Zadní Luhy 405, Zlín 763 02</h3>

<h3 className='desktop-kontakt-2'><b><u>IČ :</u></b> 071 20 362</h3>






















</div>
  );
}

export default Desktop;
