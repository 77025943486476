import React from 'react';
import './mobile-main.css';
import "./mobile-zakazane-styly.css";
import "animate.css";

function Mobile() {
  return (
    <div>
    <div className='react-body'></div>

    <img src="img/1.png" loading="eager" alt='developer-hala-ppl' className='backround-image1'></img>

    <img className='loginko' alt='stavebni-developer' src='img/main-logo.png'></img>

    <img className='menu' alt='developer-praha' src='img/hamburger-menu.png'></img>

    <p className='prvni-text animate__animated animate__backInLeft animate__delay-0.5s'>Kompletní servis pro development</p>

    <p className='druhy-text animate__animated animate__backInLeft animate__delay-0.5s'>Společně budujeme vaši budoucnost a prosperitu.</p>

    <div className='carka-1 animate__animated animate__backInLeft animate__delay-0.5s'></div>

    <p className='email-1 animate__animated animate__backInLeft animate__delay-0.5s'>
      <a className='odkaz' href="mailto:simpleeconomy@seznam.cz">simpleeconomy@seznam.cz</a>
    </p>

    <button className='more-info-1 animate__animated animate__backInLeft animate__delay-0.5s'>
      <a className='odkaz2' href="#kontakt" to="kontakt">KONTAKTUJTE NÁS ></a>
    </button>

    <div className='carenka-1 animate__animated animate__backInLeft animate__delay-0.5s'></div>

    <p className='sluzby-1 animate__animated animate__backInLeft animate__delay-0.5s'>SLUŽBY</p>
    <p className='sluzby-2 animate__animated animate__backInLeft animate__delay-0.5s'><u>Realizace vašich vizí od A do Z</u></p>

    <p className='sluzby-3 animate__animated animate__backInLeft animate__delay-0.5s'>> Projektové řízení</p>

    <p className='sluzby-4 animate__animated animate__backInLeft animate__delay-0.5s'>> Služby developera</p>

    <p className='sluzby-4 animate__animated animate__backInLeft animate__delay-0.5s'>> Pozemkové úpravy</p>

    <div className='carenka-2 animate__animated animate__backInLeft animate__delay-0.5s'></div>
    <div className='carenka-3 animate__animated animate__backInLeft animate__delay-0.5s'></div>


    <img className='obrazek-1' alt='hala jusda' src='img/jusda.jpg'></img>
    <p className='prvni-jmeno'><text className='sirsi'>Projekt Jusda</text>, Pardubice 2017</p>
    <p className='druhe-jmeno'><text className='sirsi-2'>Hala 10 000 m², pozemek 20 000 m²</text></p>


    <img className='obrazek-2' alt='hala nika' src='img/nika.png'></img>
    <p className='prvni-jmeno'><text className='sirsi'>Projekt Nika Logistics</text>, Pardubice 2018</p>
    <p className='druhe-jmeno'><text className='sirsi-2'>Hala 10 000 m², pozemek 18 000 m²</text></p>


    <img className='obrazek-3' alt='hala vetro' src='img/vetro.jpg'></img>
    <p className='prvni-jmeno'><text className='sirsi'>Projekt Vetro</text>, Hradec Králové 2019</p>
    <p className='druhe-jmeno'><text className='sirsi-2'>Hala 15 000 m², pozemek 30 000 m²</text></p>
    

    <img className='obrazek-3' alt='demolice hradec kralove' src='img/pozemek.png'></img>
    <p className='prvni-jmeno'><text className='sirsi'>Projekt Revitalizace brownfieldu,</text><br></br> Hradec Králové 2020</p>
    <p className='druhe-jmeno'><text className='sirsi-2'>Pozemek 50 000 m²</text></p>


    <img className='obrazek-3' alt='hala ppl' src='img/PPL_obrázek.jpg'></img>
    <p className='prvni-jmeno'><text className='sirsi'>Projekt PPL</text>, Hradec Králové 2021</p>
    <p className='druhe-jmeno'><text className='sirsi-2'>Hala 10 000 m², pozemek 36 000 m²</text></p>


    <img className='obrazek-3' alt='hala prazdroj' src='img/prazdroj.PNG'></img>
    <p className='prvni-jmeno'><text className='sirsi'>Projekt Prazdroj</text>, Hradec Králové 2022</p>
    <p className='druhe-jmeno'><text className='sirsi-2'>Hala 3 600 m², pozemek 8 900 m²</text></p>


    <img className='obrazek-3' alt='solarni elektrarna' src='img/fve.PNG'></img>
    <p className='prvni-jmeno'><text className='sirsi'>FVE 990 kWp</text>, Hradec Králové 2023</p>
    <p className='druhe-jmeno'><text className='sirsi-2'>Pozemek 15 000 m²</text></p>


    <img className='obrazek-3' alt='hala herales' src='img/heraues-foto.png'></img>
    <p className='prvni-jmeno'><text className='sirsi'>Projekt Heraeus</text>, Hradec Králové 2024</p>
    <p className='druhe-jmeno'><text className='sirsi-2'>Hala 4 890 m², pozemek 9 500 m²<text className='cervena'></text></text></p>


    <a href="https://www.youtube.com/watch?v=48ke9BDg3_g">
      <img target="blank" className='obrazek-3' alt='hala D' src='img/halad.png'></img>
    </a>
    <p className='prvni-jmeno'><text className='sirsi'>Projekt Hala D</text>, Hradec Králové 2025</p>
    <p className='druhe-jmeno'><text className='sirsi-2'>Hala 10 200 m², pozemek 25 000 m² - <text className='cervena'>příprava pro zákazníka</text></text></p>
    
    <br></br>
    <text className='druhe-jmeno'><text className='sirsi-2'>Vizualizace haly</text></text>
    <a href="https://www.youtube.com/watch?v=48ke9BDg3_g" className='druhe-jmeno2'><br></br><text className='tensi-2'>https://www.youtube.com/skladova-hala-hradec-kralove</text></a>
    


    {/* <button className='more-info-2'>VÍCE Z REFERENCÍ > </button> */}

    <br></br>


    <div className='carenka-2'></div>
    <div className='carenka-3'></div>

    <br></br>

    <p id='kontakt' className='kontaktni-informace'><u>KONTAKTNÍ INFORMACE</u></p>

    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20848.61750496339!2d17.602082680887715!3d49.21806321623984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47130ced88a4f037%3A0x8b4d87afd83438f9!2sZadn%C3%AD%20luhy%20405%2C%20763%2002%20Zl%C3%ADn%204-Louky!5e0!3m2!1scs!2scz!4v1710934382589!5m2!1scs!2scz" className='mapicka' loading="lazy"></iframe> */}

    <p className='email-jedna'><text className='sirsi'>Společnost : </text>SIMPLE ECONOMY s.r.o.</p>
    <p className='dalsi-jedna'><text className='sirsi'>Email : </text>simpleeconomy@seznam.cz</p>
    {/* <p className='email-jedna'><text className='sirsi'>Email : </text>simpleeconomy@seznam.cz</p>
    <p className='dalsi-jedna'><text className='sirsi'>Společnost : </text>simple economy s.r.o.</p> */}
    <p className='dalsi-jedna'><text className='sirsi'>Telefon : </text>+420 724 582 843</p>
    <p className='dalsi-jedna'><text className='sirsi'>Adresa : </text>Zadní Luhy 405, Zlín 763 02</p>


    <div className='podneco-jedna'></div>


    <p className='dalsi-jedna-dva'><text className='sirsi'>IČ : </text>071 20 362</p>
    <p className='dalsi-jedna-tri'><text className='sirsi'>DIČ : </text>CZ 071 20 362</p>

    <p className='zaver'>© 2023 - 2024 SIMPLE ECONOMY s.r.o.</p>









</div>
  );
}

export default Mobile;
