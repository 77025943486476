import React from 'react';
import "animate.css";
import Mobile from "./komponenty/mobile/mobile";
import Desktop from "./komponenty/desktop/desktop";

function App() {
  return (
    <div>
    
    <Mobile />
    <Desktop />







</div>
  );
}

export default App;
